const { ErrorMessage } = require("formik");

const CustomInput = ({ label, name, ...props }) => {
	return (
		<div className="">
			<label className="text-[1.3rem] font-medium text-gray my-[.5rem]">{label}</label>
			<input
				type="text"
				name={name}
				className="bg-transparent text-[#5E6C85] border border-[#919BAF66] rounded-[6px] px-[2rem] py-[1rem] w-full text-[1.6rem]"
				{...props}
			/>
			<ErrorMessage name={name} component="div" className="text-red-500 mt-2 text-[1.2rem]" />
		</div>
	);
};

export default CustomInput;
