export const PRODUCT_INFO = {
	schoolteller: {
		header: "Simplified Collections,<br/>Settlements & Solutions for Schools",
		subtitle: "Goodbye to bulky username and passwords. Generate student invoice and make payments.",
		name: "SchoolTeller",
	},
	examcentre: {
		header: "One Profile,<br/> Multiple School Choices",
		subtitle:
			"Complete an application in just 10 minutes while choosing from over 40 exam venues in Nigeria, South Africa, USA and United Kingdom.",
		name: "ExamCentre",
	},
	schoolrevs: {
		header: "School Management<br/>Made Easy",
		subtitle: "A suite of productive tools for school management.",
		name: "SchoolRevs",
	},
	schoolcube: {
		header: "School Management<br/>Made for You",
		subtitle: "A Perfect School Management Solution designed With You In Mind.",
		name: "SchoolCube",
	},
	classcube: {
		header: "Smart Digital Learning<br/>for Schools and Businesses",
		subtitle:
			"An online learning management system with features that help institutions and business manage online classes using videos, audio, documents, or live classes.",
		name: "ClassCube",
	},
};

export const STATES = [
	{ value: "ABIA", label: "ABIA" },
	{ value: "ABUJA", label: "ABUJA" },
	{ value: "ADAMAWA", label: "ADAMAWA" },
	{ value: "AKWA IBOM", label: "AKWA IBOM" },
	{ value: "ANAMBRA", label: "ANAMBRA" },
	{ value: "BAUCHI", label: "BAUCHI" },
	{ value: "BAYELSA", label: "BAYELSA" },
	{ value: "BENUE", label: "BENUE" },
	{ value: "BORNO", label: "BORNO" },
	{ value: "CROSS - RIVER", label: "CROSS - RIVER" },
	{ value: "DELTA", label: "DELTA" },
	{ value: "EBONYI", label: "EBONYI" },
	{ value: "EDO", label: "EDO" },
	{ value: "EKITI", label: "EKITI" },
	{ value: "ENUGU", label: "ENUGU" },
	{ value: "GOMBE", label: "GOMBE" },
	{ value: "IMO", label: "IMO" },
	{ value: "JIGAWA", label: "JIGAWA" },
	{ value: "KADUNA", label: "KADUNA" },
	{ value: "KANO", label: "KANO" },
	{ value: "KATSINA", label: "KATSINA" },
	{ value: "KEBI", label: "KEBI" },
	{ value: "KOGI", label: "KOGI" },
	{ value: "KWARA", label: "KWARA" },
	{ value: "LAGOS", label: "LAGOS" },
	{ value: "NASARAWA", label: "NASARAWA" },
	{ value: "NIGER", label: "NIGER" },
	{ value: "OGUN", label: "OGUN" },
	{ value: "ONDO", label: "ONDO" },
	{ value: "OSUN", label: "OSUN" },
	{ value: "OYO", label: "OYO" },
	{ value: "PLATEAU", label: "PLATEAU" },
	{ value: "RIVERS", label: "RIVERS" },
	{ value: "SOKOTO", label: "SOKOTO" },
	{ value: "TARABA", label: "TARABA" },
	{ value: "YOBE", label: "YOBE" },
	{ value: "ZAMFARA", label: "ZAMFARA" },
];

export const SCHOOL_TYPES = [
	{ value: "", label: "Select school type" },
	{ value: "1", label: "Creche / Day Care" },
	{ value: "2", label: "Creche / Day Care, Nursery" },
	{ value: "3", label: "Nursery & Primary" },
	{ value: "4", label: "Creche / Day Care, Nursery and Primary" },
	{ value: "5", label: "Secondary" },
	{ value: "6", label: "Creche / Day Care, Nursery, Primary, Secondary" },
	{ value: "7", label: "Sixth Form College" },
	{ value: "8", label: "Diploma" },
	{ value: "9", label: "Tertiary" },
	{ value: "10", label: "Training Centre/Institute" },
];
