import { ErrorMessage } from "formik";
import React from "react";
import ReactSelect from "react-select";

const CustomSelect = ({ options, label, placeholder, containerClassName, searchable, disablePlaceholder, ...rest }) => {
	return (
		<div>
			{!!label && <label className="text-[1.3rem] font-medium text-gray my-[.5rem]">{label}</label>}
			<div className={`${containerClassName} !border !border-lightGray !rounded-[6px]`}>
				<ReactSelect
					isSearchable
					options={options}
					placeholder={placeholder}
					classNames={{
						control: () => "border border-[#919BAF66] !rounded-[6px] !shadow-none",
						indicatorSeparator: () => "!hidden",
						placeholder: () => "!text-[1.3rem] !text-[#A0A8B6] !font-medium capitalize",
						input: () => "!text-[1.3rem] !text-gray !font-medium",
						singleValue: () => "!text-[1.3rem] !text-gray !font-medium capitalize",
						menuList: () => "!text-[1.3rem] !text-gray !font-medium capitalize",
						valueContainer: () => "!py-[0.8rem] !px-[2rem]",
						dropdownIndicator: () => "!text-gray",
					}}
					theme={(theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary: "var(--primary-color)",
						},
					})}
					{...rest}
				/>
			</div>
			{!!rest["name"] && <ErrorMessage name={rest["name"]} component="div" className="block text-[1.2rem] mt-2 text-red-500" />}
		</div>
	);
};

export default CustomSelect;
